import React, { FC, PropsWithChildren } from "react"
import styled from "styled-components"

interface TextBoxProps {
  bottomLine?: boolean
  bottomDot?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  > p {
    text-align: center;
    max-width: 600px;
    padding: 10px 0;
    font-size: 18px;
    > a {
      text-decoration: none;
      color: currentColor;
      font-weight: bold;
    }
  }
`

const Line = styled.div`
  height: 3px;
  width: 70%;
  background-color: ${({ theme }) => theme.colors.primary};
  margin: 10px 0;
`

const Dot = styled.div`
  width: 11px;
  height: 11px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
`

export const TextBox: FC<PropsWithChildren<TextBoxProps>> = ({
  children,
  bottomLine,
  bottomDot,
}) => {
  return (
    <Container>
      <p>{children}</p>
      {bottomLine && <Line />}
      {bottomDot && <Dot />}
    </Container>
  )
}

export default TextBox
