import React from "react"
import styled from "styled-components"
import { PageTitle } from "../../components/PageTitle"
import { Layout } from "../../layout/Layout"
import { TextBox } from "../../components/TextBox"
import HelmetData from "../../components/HelmetData"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

export const StrefaKursanta = () => {
  return (
    <Layout>
      <PageTitle title="STREFA KURSANTA" />
      <HelmetData page="Strefa Kursanta" canon="/strefa-kursanta" />
      <Content>
        <TextBox bottomDot>
          Jak uzyskać <strong>PKK</strong> (Profil Kandydata na Kierowcę)
        </TextBox>
        <TextBox bottomLine>Potrzebne będzie:</TextBox>
        <TextBox bottomLine>
          Orzeczenie lekarskie o braku przeciwskazań zdrowotnych do kierowania
          pojazdem (u nas dopłacamy do badań)
        </TextBox>
        <TextBox bottomLine>
          Zdjęcie biometryczne kolorowe (jak do dowodu)
        </TextBox>
        <TextBox bottomLine>
          Osoby niepełnoletnie – zgodę rodzica (opiekuna) na uczestnictwo w
          kursie,
        </TextBox>
        <TextBox bottomDot>
          Dowód osobisty, tymczasowy dowód osobisty, paszport
        </TextBox>
        <TextBox bottomDot>
          Osoby niepełnoletnie mogą rozpocząć szkolenie na 3 m-ce przed
          ukończeniem 18 lat, a do egzaminu mogą przystąpić na 1 m-c przed
          ukończeniem tego wieku.
        </TextBox>
        <TextBox bottomDot>
          Po PKK kandydat udaje się do Wydziału Komunikacji lub Starostwa
          zgodnie ze swoim miejscem zamieszkania. W Łodzi jest to Wydział Praw
          Jazdy i Rejestracji Pojazdów ul. Smugowa 26a (1 piętro),
        </TextBox>
        <TextBox>
          Na wizytę umawiamy się poprzez stronę -
          <a target="_blank" href="https://wizyty.uml.lodz.pl">
            {" "}
            wizyty.uml.lodz.pl
          </a>{" "}
          następnie klikamy{" "}
          <strong>
            Wydział Spraw Obywatelskich i Komunikacji – Oddział Dowodów
            Osobistych i Ewidencji Ludności oraz Oddział Praw Jazdy
          </strong>{" "}
          i zaznaczamy <strong> Profil Kandydata na Kierowcę.</strong>
        </TextBox>
      </Content>
      <PageTitle title="Zapraszamy" marginBottom={30} />
    </Layout>
  )
}

export default StrefaKursanta
